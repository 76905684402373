var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.containerClass,
    { 'w-full' : !_vm.isMinimal }
  ],attrs:{"id":"select-field-component"}},[_c('ValidationProvider',{attrs:{"name":_vm.id,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [(_vm.label != '')?_c('Header5',{attrs:{"label":_vm.label}}):_vm._e(),_vm._t("label_"),(_vm.note != '')?_c('span',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.note)+" ")]):_vm._e(),_vm._t("note_"),(!_vm.isHideInput)?_c('vSelect',{ref:"inputField",class:[
          { 'text-red-500 border-red-500': errors.length > 0 },
          { 'mt-2': !_vm.isMinimal },
          { 'minimal-design' : _vm.isMinimal } ],attrs:{"id":_vm.id,"multiple":_vm.multiple,"name":_vm.id,"options":_vm.options,"value":_vm.value,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":_vm.onUpdateField},scopedSlots:_vm._u([{key:"selected-option",fn:function(data){return [_vm._t("selected_option_",null,{"option":data})]}},{key:"option",fn:function(data){return [_vm._t("option_",null,{"option":data})]}}],null,true)}):_vm._e(),(!_vm.isHideInput)?_c('ErrorMessage',{staticClass:"mt-1",attrs:{"errors":errors.concat( _vm.errorMessages)}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }