<template>
  <div class="flex flex-col w-full">
    <!-- <InputField
      id="username"
      v-model="form.username"
      type="text"
      class="my-2"
      :rules="`required|unique:users,username,${form.id}`"
      :errorMessages="errorMessages.username"
      :disabled="form.id ? true : false"
      @input="onChange"
    /> -->
    <!-- <InputField
      id="company_name"
      v-model="form.name"
      type="text"
      class="my-2"
      label="Company Name"
      rules="required"
      :errorMessages="errorMessages.name"
      @input="onChange"
    /> -->
    <TextAreaField
      id="text"
      v-model="form.company.description"
      class="my-2"
      label="Beschreibung deines Unternehmens"
      rules="max:800"
      :errorMessages="errorMessages.description"
      @input="onChange"
    />
    <InputField
      id="company_web_site"
      v-model="form.company.url"
      type="text"
      class="my-2"
      label="Webseite"
      rules=""
      :errorMessages="errorMessages.url"
      @input="onChange"
    />

    <div>
      <span class="block mb-1 text-sm">Logo</span>
      <label
        class="file flex flex-col input-field mb-3 px-3 py-3 rounded-sm text-2xs text-center cursor-pointer" style="background-color: rgb(247, 247, 247);"
        >
            <i class="fa fa-cloud-upload-alt mb-2 mr-1 text-3xl text-center"></i> (Ideales Maß ist 250px x 100px)
            <input
              type="file"
              accept="image/*"
              aria-label="File browser"
              @change="(e) => croppie(e, 'logo')"
              />
            <span class="file-custom"></span>
        </label>
        <section class="relative hidden">
          <vue-croppie
            ref="logo"
            :enableOrientation="true"
            :enableResize="false"
            :boundary="{ width: 250, height: 100 }"
            :viewport="{ width: 250, height: 100, 'type':'square' }"
            @update="update('logo', 'logo')"
          />
        </section>
    </div>

    <div>
      <span class="block mb-1 text-sm">Titelbild</span>
      <label
        class="file flex flex-col input-field mb-3 px-3 py-3 rounded-sm text-2xs text-center cursor-pointer" style="background-color: rgb(247, 247, 247);"
        >
            <i class="fa fa-cloud-upload-alt mb-2 mr-1 text-3xl text-center"></i> (Ideales Maß ist 1920px x 1080px)
            <ValidationProvider rules="" name="header_logo">
            <input
              type="file"
              accept="image/*"
              aria-label="File browser example"
              @change="(e) => croppie(e, 'header_logo')"
              />
            </ValidationProvider>
            <span class="file-custom"></span>
        </label>
        <section v-if="header_logo && header_logo != '' && header_logo == form.company.header_logo" class="relative border" style="width: 250px; height: 141px;">
          <img :src="onSetLogo('set', header_logo)" style="width: auto; height: 100%;"/>
        </section>
        <section class="relative hidden">
          <vue-croppie
            ref="header_logo"
            :enableOrientation="true"
            :enableResize="false"
            :boundary="{ width: 384, height: 216 }"
            :viewport="{ width: 384, height: 216, 'type':'square' }"
            @update="update('header_logo', 'header_logo')"
          />
        </section>
    </div>

    <InputField
      id="vat_number"
      v-model="form.company.vat_number"
      type="text"
      class="my-2"
      label="UID Nummer"
      :errorMessages="errorMessages.vat_number"
      @input="onChange"
    />
    <SelectField
      id="Region"
      v-model="form.company.region_id"
      :options="regions"
      class="my-2"
      label="Region"
      rules="required"
      :errorMessages="errorMessages.region_id"
      @input="onChange"
    />
    <div v-if="GLOBAL_SETTING && AUTH_USER.isAuth" class="mt-2 mb-4 text-sm text-center">
      {{
        `Deine Kommission ist ${ settings ? settings.sales_commission_percentage : '5' }% und ${ settings ? $helpers.convertCurrency(settings.sales_commission_euro) : '5,00 €' } pro Gutscheinverkauf zuzüglich 20% MwSt.`
      }}
    </div>
  </div>
</template>
<script>
  import SelectField from '_components/Form/SelectField';
  import InputField from '_components/Form/InputField';
  import TextAreaField from '_components/Form/TextAreaField';
  import FileInputField from "_components/Form/FileInputField";
  import Header5 from '_components/Headers/Header5';

  export default {
    components: {
      SelectField,
      InputField,
      TextAreaField,
      FileInputField,
      Header5,
    },
    props: {
      errorMessages: {
        type: Array,
        default() {
          return []
        }
      },
      data: {
        type: Object,
        default() {
          return null
        }
      },
      isDefaultRegions: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        logo: '',
        header_logo: '',
        // form: {
        //   username: '',
        //   name: '',
        //   description: '',
        //   url: '',
        //   logo: '',
        //   region: '',
        //   vat_number: ''
        // },
        form: {
          id: null,
          username: '',
          firstName: '',
          lastName: '',
          email: '',
          address: '',
          city: '',
          zip_code: '',
          phone_number: '',
          password: '',
          confirmPassword: '',
          company: {
            name: '',
            description: '',
            url: '',
            logo: '',
            header_logo: '',
            region: '',
            vat_number: ''
          }
        },
        settings: null,
        regions: null
      }
    },
    computed: {
      REGIONS()
      {
        return this.$store.getters.REGIONS
      },
      GLOBAL_SETTING()
      {
        return this.$store.getters.GLOBAL_SETTING
      },
      USER_SETTING()
      {
        return this.$store.getters.USER_SETTING
      },
      AUTH_USER()
      {
        return this.$store.getters.AUTH_USER
      }
    },
    watch: {
      data(newVal)
      {
        this.onSetForm()
      },
      GLOBAL_SETTING()
      {
        this.onSetSettings()
      },
      USER_SETTING()
      {
        this.onSetSettings()
      },
    },
    mounted() {
      this.onSetForm()
      this.onSetSettings()

      if(this.isDefaultRegions) {
        const ids = [1, 2, 3, 4]
        this.regions = this.REGIONS.filter(data => ids.includes(data.id))
      } else {
        const ids = [5, 6, 7, 8, 9, 10, 11, 12]
        this.regions = this.REGIONS.filter(data => ids.includes(data.id))
      }
    },
    methods: {
      onSetSettings()
      {
        this.settings = this.GLOBAL_SETTING
        if( this.USER_SETTING ) {
          this.settings = this.USER_SETTING
        }
      },
      onChange()
      {
        this.$emit('onChange', this.form)
      },
      onChangeLogo(data)
      {
        if(data.length > 0) {
          let reader = new FileReader();
          reader.readAsDataURL(data[0]);
          reader.onload = () => {
            this.form.company.logo = data[0]
            this.logo = reader.result
            this.onChange()
          }
        } else {
          this.logo = ''
          this.form.company.logo = ''
          this.onChange()
        }
      },
      onSetImage(action, value)
      {
        if( action == 'set' ) {
          return (value.search('base64') < 0) ? `${process.env.VUE_APP_API_BASE_URL}/storage/${value}` : value
        } else {
          this.form.company.logo = ''
          this.logo = ''
        }
      },
      onSetForm()
      {
        if( this.data?.company ) {
          if( typeof this.data?.company?.logo == 'string'  ) {
            this.logo = this.data.company.logo
          }
          this.form = {
            ...this.form,
            ...this.data,
            company: {
              ...this.form.company,
              ...this.data.company,
            }
          }
        }
      },
      croppie (e, ref) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.$refs[ref].$vnode.elm.parentElement.classList.remove('hidden')
          this.$refs[ref].bind({
            url: e.target.result
          });
        };

        reader.readAsDataURL(files[0]);
      },
      // CALBACK USAGE
      crop(ref, form) {
          // Here we are getting the result via callback function
          // and set the result to this.cropped which is being
          // used to display the result above.
          let size = { width: 250, height: 100};

          if(form == 'header_logo') {
            size = { width: 1920, height: 1080};
          }
          let options = {
              type: 'base64',
              format: 'jpeg',
              size,
              quality: 1,
          }
          this.$refs[ref].result(options, (output) => {
            this.form.company[ref] = output
          });
      },
      update(ref, form) {
        this.crop(ref, form)
      },
    }
  }
</script>
<style lang="css" scoped>
  .company-logo {
    width: 250px;
    height: 100px;
    border: 1px solid #ccc;
  }
  input[type="file"] {
    display: none;
  }
</style>